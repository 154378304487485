// This is called a "splat route" and as it's in the root `/app/routes/`
// directory, it's a catchall. If no other routes match, this one will and we
// can know that the user is hitting a URL that doesn't exist. By throwing a
// 404 from the loader, we can force the error boundary to render which will
// ensure the user gets the right status code and we can display a nicer error
// message for them than the Remix and/or browser default.

import { GeneralErrorBoundary } from '#app/components/common/error-boundary'
import Error404, { icons, taglines } from '#app/components/error-pages/404'

export async function loader() {
	throw Response.json(
		{
			message: taglines[Math.floor(Math.random() * taglines.length)],
			icon: Object.keys(icons)[
				Math.floor(Math.random() * Object.keys(icons).length)
			],
		},
		{
			status: 404,
			statusText: 'Not found',
		},
	)
}

export default function NotFound() {
	// due to the loader, this component will never be rendered, but we'll return
	// the error boundary just in case.
	return <ErrorBoundary />
}

export function ErrorBoundary() {
	return (
		<GeneralErrorBoundary
			statusHandlers={{
				404: Error404,
			}}
		/>
	)
}
